"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersService = void 0;
const base_service_1 = require("./base.service");
class UsersService extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
     * Get a user
     * @param pipelineRetriverRequest
     * @returns Promise<PagerResponse<UserObject>>
     * @see https://developers.pipedrive.com/docs/api/v1/Pipelines#get_pipelines
    **/
    listUsers(usersListRequest) {
        const url = this.getBaseURL(`/users/`);
        return super.get(url, this.paramsConfig(usersListRequest));
    }
}
exports.UsersService = UsersService;
