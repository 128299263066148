"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateCommentInDeal = void 0;
const tslib_1 = require("tslib");
const base_service_1 = require("./base.service");
class CreateCommentInDeal extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
   * Create Comment In Deal
   * @param NoteCreateRequest
   * @returns Promise<PagerResponse<NoteObject>>
   * @see https://developers.pipedrive.com/docs/api/v1/Notes
   */
    createCommentInDeal(noteCreateRequest) {
        const { api_token } = noteCreateRequest, data = tslib_1.__rest(noteCreateRequest, ["api_token"]);
        const url = this.getBaseURL(`/notes?`);
        return super.post(url, data, this.paramsConfig(noteCreateRequest));
    }
}
exports.CreateCommentInDeal = CreateCommentInDeal;
