"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PipelinesService = void 0;
const base_service_1 = require("./base.service");
class PipelinesService extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
     * Get a pipeline
     * @param pipelineRetriverRequest
     * @returns Promise<PagerResponse<PipelineObject>>
     * @see https://developers.pipedrive.com/docs/api/v1/Pipelines#get_pipelines
    **/
    listPipelines(pipelinesListRequest) {
        const url = this.getBaseURL(`/pipelines/`);
        return super.get(url, this.paramsConfig(pipelinesListRequest));
    }
}
exports.PipelinesService = PipelinesService;
