"use strict";
/**
 * Documentarion:
 * https://developers.pipedrive.com/docs/api/v1
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.pipedriveProviders = void 0;
exports.pipedriveProviders = {
    PIPEDRIVE_API_URL: 'https://api.pipedrive.com/v1',
};
