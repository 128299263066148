"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationService = void 0;
const base_service_1 = require("./base.service");
class OrganizationService extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
     * Get a organization
     * @param organizationRetriverRequest
     * @returns Promise<PagerResponse<OrganizationObject>>
     * @see https://developers.pipedrive.com/docs/api/v1/Organizations#get_organizations
     **/
    listOrganizations(organizationListRequest) {
        const url = this.getBaseURL(`/organizations/`);
        return super.get(url, this.paramsConfig(organizationListRequest));
    }
}
exports.OrganizationService = OrganizationService;
