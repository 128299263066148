"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePerson = void 0;
const tslib_1 = require("tslib");
const base_service_1 = require("./base.service");
class CreatePerson extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
     * Create a person
     * @param personCreateRequest
     * @returns Promise<ObjectResponse<PersonObject>>
     * @see https://developers.pipedrive.com/docs/api/v1/Persons#create_person
     **/
    createPersonId(personCreateRequest) {
        const { api_token } = personCreateRequest, data = tslib_1.__rest(personCreateRequest, ["api_token"]);
        const url = this.getBaseURL(`/persons?`);
        return super.post(url, data, this.paramsConfig(personCreateRequest));
    }
}
exports.CreatePerson = CreatePerson;
