"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrendPipedriveAPIService = void 0;
const tslib_1 = require("tslib");
const comment_service_1 = require("./services/comment.service");
const deal_service_1 = require("./services/deal.service");
const lead_service_1 = require("./services/lead.service");
const organization_service_1 = require("./services/organization.service");
const person_service_1 = require("./services/person.service");
const pipelines_service_1 = require("./services/pipelines.service");
const stages_service_1 = require("./services/stages.service");
const users_service_1 = require("./services/users.service");
class TrendPipedriveAPIService {
    constructor() {
        this.pipelinesService = new pipelines_service_1.PipelinesService();
        this.stagesService = new stages_service_1.StagesService();
        this.createPerson = new person_service_1.CreatePerson();
        this.createDeal = new deal_service_1.CreateDeal();
        this.createCommentInDeal = new comment_service_1.CreateCommentInDeal();
        this.usersService = new users_service_1.UsersService();
        this.createLead = new lead_service_1.CreateLead();
        this.organizationsService = new organization_service_1.OrganizationService();
    }
    /**
     * List assistants
     */
    listPipelines(pipelinesListRequest) {
        return this.pipelinesService.listPipelines(pipelinesListRequest);
    }
    /**
     * List stages
     */
    listStages(stageListRequest) {
        return this.stagesService.listStages(stageListRequest);
    }
    /**
     * Create person
     */
    createPersonId(personCreateRequest) {
        return this.createPerson.createPersonId(personCreateRequest);
    }
    /**
     * Create deal
     */
    createDealId(dealCreateRequest) {
        return this.createDeal.createDealId(dealCreateRequest);
    }
    /**
     * Create note
     */
    createNote(noteCreateRequest) {
        return this.createCommentInDeal.createCommentInDeal(noteCreateRequest);
    }
    /**
     * Get Users
     */
    listUsers(usersListRequest) {
        return this.usersService.listUsers(usersListRequest);
    }
    /**
     * Create Lead
     */
    createLeadId(leadCreateRequest) {
        return this.createLead.createLeadId(leadCreateRequest);
    }
    /**
     * Get Organizations
     */
    listOrganizations(organizationListRequest) {
        return this.organizationsService.listOrganizations(organizationListRequest);
    }
    /**
    * Create person and lead sequentially
    * @param personCreateRequest Data for creating the person
    * @param leadCreateRequest Data for creating the lead
    * @param noteCreateRequest Data for creating the note (optional)
    * @returns Promise with created person and lead
    */
    createPersonAndLead(personCreateRequest, leadCreateRequest, noteCreateRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const createdPerson = yield this.createPersonId(personCreateRequest);
                if (!createdPerson.data || !createdPerson.data.id) {
                    throw new Error('Error in create person');
                }
                const personId = Number(createdPerson.data.id);
                const leadWithPersonId = Object.assign(Object.assign({}, leadCreateRequest), { person_id: personId, owner_id: personCreateRequest.owner_id });
                const createdLead = yield this.createLeadId(leadWithPersonId);
                if (!createdLead.data || !createdLead.data.id) {
                    throw new Error('Error in create lead');
                }
                let createdPersonNote;
                let createdNote;
                if (noteCreateRequest) {
                    const personNoteRequest = Object.assign(Object.assign({ api_token: personCreateRequest.api_token }, noteCreateRequest), { person_id: personId });
                    createdPersonNote = yield this.createNote(personNoteRequest);
                    const fullNoteCreateRequest = Object.assign(Object.assign({ api_token: personCreateRequest.api_token }, noteCreateRequest), { person_id: personId, lead_id: createdLead.data.id });
                    createdNote = yield this.createNote(fullNoteCreateRequest);
                }
                return Object.assign({ person: createdPerson, lead: createdLead }, (createdNote && { note: createdNote }));
            }
            catch (error) {
                console.error('Erro in create', error);
                throw error;
            }
        });
    }
    /**
     * Create person and deal sequentially with optional notes
     * @param personCreateRequest Data for creating the person
     * @param leadCreateRequest Data for creating the lead
     * @param noteCreateRequest Data for creating the note (optional)
     * @returns Promise with created person, deal and notes
     */
    createPersonAndDeal(personCreateRequest, dealCreateRequest, noteCreateRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const createdPerson = yield this.createPersonId(personCreateRequest);
                if (!createdPerson.data || !createdPerson.data.id) {
                    throw new Error('error in create person');
                }
                const personId = Number(createdPerson.data.id);
                const dealWithPersonId = Object.assign(Object.assign({}, dealCreateRequest), { person_id: personId });
                const createdDeal = yield this.createDealId(dealWithPersonId);
                if (!createdDeal.data || !createdDeal.data.id) {
                    throw new Error('Error in create lead');
                }
                const dealId = Number(createdDeal.data.id);
                let createdPersonNote;
                let createdDealNote;
                if (noteCreateRequest) {
                    // create anot for person
                    const personNoteRequest = Object.assign(Object.assign({ api_token: personCreateRequest.api_token }, noteCreateRequest), { person_id: personId });
                    createdPersonNote = yield this.createNote(personNoteRequest);
                    // create note for deal
                    const dealNoteRequest = Object.assign(Object.assign({ api_token: personCreateRequest.api_token }, noteCreateRequest), { deal_id: dealId });
                    createdDealNote = yield this.createNote(dealNoteRequest);
                }
                return Object.assign(Object.assign({ person: createdPerson, deal: createdDeal }, (createdPersonNote && { personNote: createdPersonNote })), (createdDealNote && { dealNote: createdDealNote }));
            }
            catch (error) {
                console.error('error in create', error);
                throw error;
            }
        });
    }
}
exports.TrendPipedriveAPIService = TrendPipedriveAPIService;
