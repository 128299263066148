"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StagesService = void 0;
const base_service_1 = require("./base.service");
class StagesService extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
     * Get a stage
     * @param pipelineRetriverRequest
     * @returns Promise<PagerResponse<StageObject>>
     * @see https://developers.pipedrive.com/docs/api/v1/Pipelines#get_pipelines
     **/
    listStages(stageListRequest) {
        const url = this.getBaseURL(`stages?pipeline_id=${stageListRequest.pipeline_id}`);
        return super.get(url, this.paramsConfig(stageListRequest));
    }
}
exports.StagesService = StagesService;
