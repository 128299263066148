"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDeal = void 0;
const tslib_1 = require("tslib");
const base_service_1 = require("./base.service");
class CreateDeal extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
     * Create Deal
     * @param DealCreateRequest
     * @returns Promise<ObjectResponse<DealObject>>
     * @see https://developers.pipedrive.com/docs/api/v1/Deals#create_deal
     */
    createDealId(dealCreateRequest) {
        const { api_token } = dealCreateRequest, data = tslib_1.__rest(dealCreateRequest, ["api_token"]);
        const url = this.getBaseURL(`deals?`);
        return super.post(url, data, this.paramsConfig(dealCreateRequest));
    }
}
exports.CreateDeal = CreateDeal;
