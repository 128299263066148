"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateLead = void 0;
const tslib_1 = require("tslib");
const base_service_1 = require("./base.service");
class CreateLead extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
     * Create Lead
     * @param LeadCreateRequest
     * @returns Promise<PagerResponse<baseParamsRequest>>
     * @see https://developers.pipedrive.com/docs/api/v1/Leads#create_lead
     */
    createLeadId(leadCreateRequest) {
        const { api_token } = leadCreateRequest, data = tslib_1.__rest(leadCreateRequest, ["api_token"]);
        const url = this.getBaseURL(`leads?`);
        console.log('data', data, 'url', url, 'leadCreateRequest', leadCreateRequest);
        return super.post(url, data, this.paramsConfig(leadCreateRequest));
    }
}
exports.CreateLead = CreateLead;
